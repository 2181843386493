<template>
  <b-modal
    id="id-modal-promotionag-select-flight-trips"
    :no-close-on-backdrop="true"
    :title="$t('promotionAg.titleSearchAirports')"
    title-class="font-medium-4 font-weight-bolder"
    body-class="p-1"
    size="lg"
    :cancel-title="$t('cancel')"
    :ok-title="$t('promotionAg.addflightTrip')"
    @ok="addTrip()"
  >
    <div class="d-flex align-items-center flex-column flex-lg-row w-100">
      <!-- SECTION From -->
      <div
        class="flex-grow-1"
        style="min-width: 300px;"
      >
        <ResultSearchAirportSelect
          :append-to-body="false"
          :data-prop.sync="startPoint"
          class="border rounded"
        />
      </div>

      <!-- ANCHOR switch -->
      <div class="mx-50">
        <b-button
          variant="white"
          class="btn-icon rounded-circle"
          @click="swapHandle"
        >
          <img
            src="@icons/swap-horizontal.svg"
            alt="Swap"
          >
        </b-button>
      </div>

      <!-- SECTION TO -->
      <div
        class="flex-grow-1"
        style="min-width: 300px;"
      >
        <ResultSearchAirportSelect
          :append-to-body="false"
          :data-prop.sync="endPoint"
          right
          class="border rounded"
        />
      </div>
    </div>
  </b-modal>
</template>

<script>
import {
  BModal,
  BButton,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { isEmpty } from 'lodash'

import store from '@/store'

import ResultSearchAirportSelect from '@flightv2/search/components/SearchAirportSelect_result.vue'
import useBookingHandle from '@flightv2/useBookingHandle'
import bookingStoreModule from '@flightv2/bookingStoreModule'

export default {
  name: 'InputFlightTrips',

  components: {
    BModal,
    BButton,
    ResultSearchAirportSelect,
  },

  setup(props, { emit }) {
    const {
      fetchAirportGroup,
      FLIGHT_APP_STORE_MODULE_NAME,
    } = useBookingHandle()

    if (!store.hasModule(FLIGHT_APP_STORE_MODULE_NAME)) {
      store.registerModule(FLIGHT_APP_STORE_MODULE_NAME, bookingStoreModule)
    }

    onUnmounted(() => {
      if (store.hasModule(FLIGHT_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(FLIGHT_APP_STORE_MODULE_NAME)
      }
    })

    if (isEmpty(store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getAirportGroup`])) { fetchAirportGroup() }

    const startPoint = ref('HAN')
    const endPoint = ref('SGN')

    const searchData = ref(null)

    function swapHandle() {
      const temp = startPoint.value
      startPoint.value = endPoint.value
      endPoint.value = temp
    }

    function addTrip() {
      emit('addTrip', `${startPoint.value}-${endPoint.value}`)
    }

    return {
      startPoint,
      endPoint,
      searchData,
      swapHandle,
      addTrip,
    }
  },
}
</script>

<style lang="scss" scoped>
#search-airport ::v-deep {
  .vs__dropdown-toggle {
    width: 100%;
  }
}
</style>
