var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "id-modal-promotionag-select-flight-trips",
      "no-close-on-backdrop": true,
      "title": _vm.$t('promotionAg.titleSearchAirports'),
      "title-class": "font-medium-4 font-weight-bolder",
      "body-class": "p-1",
      "size": "lg",
      "cancel-title": _vm.$t('cancel'),
      "ok-title": _vm.$t('promotionAg.addflightTrip')
    },
    on: {
      "ok": function ok($event) {
        return _vm.addTrip();
      }
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center flex-column flex-lg-row w-100"
  }, [_c('div', {
    staticClass: "flex-grow-1",
    staticStyle: {
      "min-width": "300px"
    }
  }, [_c('ResultSearchAirportSelect', {
    staticClass: "border rounded",
    attrs: {
      "append-to-body": false,
      "data-prop": _vm.startPoint
    },
    on: {
      "update:dataProp": function updateDataProp($event) {
        _vm.startPoint = $event;
      },
      "update:data-prop": function updateDataProp($event) {
        _vm.startPoint = $event;
      }
    }
  })], 1), _c('div', {
    staticClass: "mx-50"
  }, [_c('b-button', {
    staticClass: "btn-icon rounded-circle",
    attrs: {
      "variant": "white"
    },
    on: {
      "click": _vm.swapHandle
    }
  }, [_c('img', {
    attrs: {
      "src": require("@icons/swap-horizontal.svg"),
      "alt": "Swap"
    }
  })])], 1), _c('div', {
    staticClass: "flex-grow-1",
    staticStyle: {
      "min-width": "300px"
    }
  }, [_c('ResultSearchAirportSelect', {
    staticClass: "border rounded",
    attrs: {
      "append-to-body": false,
      "data-prop": _vm.endPoint,
      "right": ""
    },
    on: {
      "update:dataProp": function updateDataProp($event) {
        _vm.endPoint = $event;
      },
      "update:data-prop": function updateDataProp($event) {
        _vm.endPoint = $event;
      }
    }
  })], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }