var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading,
      "rounded": "sm",
      "no-fade": "",
      "variant": "transparent",
      "opacity": 0.5
    }
  }, [_c('section', [_c('validation-observer', {
    ref: "refFormObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var invalid = _ref.invalid;
        return [_c('b-form', [_c('b-card', {
          staticClass: "border mt-1",
          attrs: {
            "header-tag": "header",
            "border-variant": "info",
            "header-bg-variant": "light-info",
            "header-class": "py-1"
          },
          scopedSlots: _vm._u([{
            key: "header",
            fn: function fn() {
              return [_c('div', {
                staticClass: "d-flex align-items-center justify-content-between w-100"
              }, [_c('h6', {
                staticClass: "m-0"
              }, [_vm._v(" " + _vm._s(_vm.readonly ? _vm.$t('promotionAg.edit.title') : _vm.$t('promotionAg.edit.titleEdit')) + " "), false ? _c('b-badge', {
                staticClass: "py-50",
                attrs: {
                  "variant": "primary"
                }
              }, [_vm._v(" " + _vm._s(_vm.promotionDataToEdit.code) + " ")]) : _vm._e()], 1), _vm.readonly && _vm.canEdit ? _c('b-button', {
                directives: [{
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: 'rgba(113, 102, 240, 0.15)',
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: {
                    "400": true
                  }
                }],
                staticClass: "py-75",
                attrs: {
                  "variant": "outline-primary",
                  "disabled": !_vm.canAccess('customer.updateCustomer')
                },
                on: {
                  "click": _vm.handleEdit
                }
              }, [_c('feather-icon', {
                staticClass: "mr-50",
                attrs: {
                  "icon": "RefreshCwIcon"
                }
              }), _c('span', {
                staticClass: "align-middle"
              }, [_vm._v(_vm._s(_vm.$t('customer.edit')))])], 1) : _vm._e(), !_vm.readonly ? _c('b-button', {
                directives: [{
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: 'rgba(113, 102, 240, 0.15)',
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: {
                    "400": true
                  }
                }],
                staticClass: "py-75",
                attrs: {
                  "variant": "outline-danger",
                  "disabled": !_vm.canAccess('customer.updateCustomer')
                },
                on: {
                  "click": _vm.handleCancelEdit
                }
              }, [_c('feather-icon', {
                staticClass: "mr-50",
                attrs: {
                  "icon": "XIcon"
                }
              }), _c('span', {
                staticClass: "align-middle"
              }, [_vm._v(_vm._s(_vm.$t('customer.cancel')))])], 1) : _vm._e()], 1)];
            },
            proxy: true
          }], null, true)
        }, [_c('b-card-body', {
          staticClass: "p-0"
        }, [_c('b-row', {
          staticClass: "mt-1 mb-3"
        }, [_c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.code')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-input', {
          attrs: {
            "maxlength": "9",
            "lazy-formatter": "",
            "disabled": true,
            "formatter": _vm.trimInput,
            "placeholder": _vm.$t('promotionAg.placeholder.code')
          },
          model: {
            value: _vm.promotionDataToEdit.code,
            callback: function callback($$v) {
              _vm.$set(_vm.promotionDataToEdit, "code", $$v);
            },
            expression: "promotionDataToEdit.code"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('promotionAg.name'),
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.name')) + " ")])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "maxlength": "100",
                  "lazy-formatter": "",
                  "disabled": _vm.readonly,
                  "formatter": _vm.trimInput,
                  "placeholder": _vm.$t('promotionAg.placeholder.name'),
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                },
                model: {
                  value: _vm.promotionDataToEdit.name,
                  callback: function callback($$v) {
                    _vm.$set(_vm.promotionDataToEdit, "name", $$v);
                  },
                  expression: "promotionDataToEdit.name"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.promotionType')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('v-select', {
          staticStyle: {
            "font-size": "1rem"
          },
          attrs: {
            "options": _vm.typeOfDiscountPaidOptions,
            "clearable": false,
            "disabled": _vm.readonly,
            "reduce": function reduce(val) {
              return val.value;
            }
          },
          scopedSlots: _vm._u([{
            key: "option",
            fn: function fn(data) {
              return [_c('span', [_vm._v(" " + _vm._s(_vm.$t("promotionAg.".concat(data.label))) + " ")])];
            }
          }, {
            key: "selected-option",
            fn: function fn(data) {
              return [_c('span', [_vm._v(" " + _vm._s(_vm.$t("promotionAg.".concat(data.label))) + " ")])];
            }
          }], null, true),
          model: {
            value: _vm.promotionDataToEdit.paidType,
            callback: function callback($$v) {
              _vm.$set(_vm.promotionDataToEdit, "paidType", $$v);
            },
            expression: "promotionDataToEdit.paidType"
          }
        })], 1)], 1), _vm.promotionDataToEdit.discountType === 'VND' ? _c('b-col', {
          staticStyle: {
            "min-width": "20%"
          },
          attrs: {
            "md": "2"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('promotionAg.discount'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.discount')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                directives: [{
                  name: "remove-non-numeric-chars",
                  rawName: "v-remove-non-numeric-chars.allNumber",
                  modifiers: {
                    "allNumber": true
                  }
                }],
                attrs: {
                  "number": "",
                  "type": "number",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "maxlength": "30",
                  "lazy-formatter": "",
                  "disabled": _vm.readonly,
                  "formatter": _vm.trimInput,
                  "placeholder": _vm.$t('promotionAg.placeholder.discountAmount')
                },
                model: {
                  value: _vm.promotionDataToEdit.discountAmount,
                  callback: function callback($$v) {
                    _vm.$set(_vm.promotionDataToEdit, "discountAmount", $$v);
                  },
                  expression: "promotionDataToEdit.discountAmount"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1) : _c('b-col', {
          staticStyle: {
            "min-width": "20%"
          },
          attrs: {
            "md": "2"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('promotionAg.discount'),
            "rules": "required|maxValue:100|min_value:0"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.discount')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                directives: [{
                  name: "remove-non-numeric-chars",
                  rawName: "v-remove-non-numeric-chars.allNumber",
                  modifiers: {
                    "allNumber": true
                  }
                }],
                attrs: {
                  "type": "number",
                  "number": "",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "max": "100",
                  "lazy-formatter": "",
                  "disabled": _vm.readonly,
                  "formatter": _vm.trimInput,
                  "placeholder": _vm.$t('promotionAg.placeholder.discountPercent')
                },
                model: {
                  value: _vm.promotionDataToEdit.discountPercent,
                  callback: function callback($$v) {
                    _vm.$set(_vm.promotionDataToEdit, "discountPercent", $$v);
                  },
                  expression: "promotionDataToEdit.discountPercent"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "md": ""
          }
        }, [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', {
                staticStyle: {
                  "opacity": "0"
                }
              }, [_vm._v(" " + _vm._s("-") + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('v-select', {
          staticStyle: {
            "font-size": "1rem"
          },
          attrs: {
            "options": _vm.typeOfDiscountOptions,
            "clearable": false,
            "disabled": _vm.readonly,
            "reduce": function reduce(val) {
              return val.value;
            }
          },
          model: {
            value: _vm.promotionDataToEdit.discountType,
            callback: function callback($$v) {
              _vm.$set(_vm.promotionDataToEdit, "discountType", $$v);
            },
            expression: "promotionDataToEdit.discountType"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Start date",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.startTime')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-datepicker', {
                attrs: {
                  "disabled": _vm.readonly,
                  "locale": _vm.$i18n.locale,
                  "placeholder": _vm.$t('placeholderSelect'),
                  "date-format-options": {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit'
                  },
                  "min": new Date(),
                  "max": _vm.promotionDataToEdit.endTime ? new Date(_vm.promotionDataToEdit.endTime) : null,
                  "show-decade-nav": "",
                  "hide-header": "",
                  "boundary": "window",
                  "no-flip": ""
                },
                model: {
                  value: _vm.promotionDataToEdit.startTime,
                  callback: function callback($$v) {
                    _vm.$set(_vm.promotionDataToEdit, "startTime", $$v);
                  },
                  expression: "promotionDataToEdit.startTime"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "End date",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.endTime')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-datepicker', {
                attrs: {
                  "reset-button": "",
                  "close-button": "",
                  "disabled": _vm.readonly,
                  "locale": _vm.$i18n.locale,
                  "placeholder": _vm.$t('placeholderSelect'),
                  "date-format-options": {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit'
                  },
                  "min": _vm.promotionDataToEdit.startTime ? new Date(_vm.promotionDataToEdit.startTime) : new Date(),
                  "show-decade-nav": "",
                  "hide-header": "",
                  "boundary": "window",
                  "no-flip": ""
                },
                model: {
                  value: _vm.promotionDataToEdit.endTime,
                  callback: function callback($$v) {
                    _vm.$set(_vm.promotionDataToEdit, "endTime", $$v);
                  },
                  expression: "promotionDataToEdit.endTime"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1)], 1), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('promotionAg.minimumPassenger'),
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.minimumPassenger')) + " ")])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                directives: [{
                  name: "remove-non-numeric-chars",
                  rawName: "v-remove-non-numeric-chars.allNumber",
                  modifiers: {
                    "allNumber": true
                  }
                }],
                attrs: {
                  "number": "",
                  "maxlength": "5",
                  "lazy-formatter": "",
                  "disabled": _vm.readonly,
                  "formatter": _vm.trimInput,
                  "placeholder": _vm.$t('promotionAg.placeholder.minimumPassenger'),
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                },
                model: {
                  value: _vm.promotionDataToEdit.minimumPassenger,
                  callback: function callback($$v) {
                    _vm.$set(_vm.promotionDataToEdit, "minimumPassenger", $$v);
                  },
                  expression: "promotionDataToEdit.minimumPassenger"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.quotaAmount')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-input', {
          directives: [{
            name: "remove-non-numeric-chars",
            rawName: "v-remove-non-numeric-chars.allNumber",
            modifiers: {
              "allNumber": true
            }
          }],
          attrs: {
            "number": "",
            "type": "number",
            "maxlength": "30",
            "lazy-formatter": "",
            "disabled": _vm.readonly,
            "formatter": _vm.trimInput,
            "placeholder": _vm.$t('promotionAg.placeholder.quotaAmount')
          },
          model: {
            value: _vm.promotionDataToEdit.quotaAmount,
            callback: function callback($$v) {
              _vm.$set(_vm.promotionDataToEdit, "quotaAmount", $$v);
            },
            expression: "promotionDataToEdit.quotaAmount"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.quotaCounter')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-input', {
          directives: [{
            name: "remove-non-numeric-chars",
            rawName: "v-remove-non-numeric-chars.allNumber",
            modifiers: {
              "allNumber": true
            }
          }],
          attrs: {
            "type": "number",
            "number": "",
            "maxlength": "30",
            "lazy-formatter": "",
            "disabled": _vm.readonly,
            "formatter": _vm.trimInput,
            "placeholder": _vm.$t('promotionAg.placeholder.quotaCounter')
          },
          model: {
            value: _vm.promotionDataToEdit.quotaCounter,
            callback: function callback($$v) {
              _vm.$set(_vm.promotionDataToEdit, "quotaCounter", $$v);
            },
            expression: "promotionDataToEdit.quotaCounter"
          }
        })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.agency')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('v-select', {
          staticClass: "v-select-wrap",
          staticStyle: {
            "font-size": "1rem"
          },
          attrs: {
            "multiple": "",
            "label": "agencyName",
            "disabled": _vm.readonly,
            "options": _vm.agenciesManagerList,
            "reduce": function reduce(val) {
              return val.agencyCode;
            },
            "filterable": false,
            "placeholder": _vm.$t('promotionAg.allAgencies')
          },
          on: {
            "open": _vm.onFocusSearchAndInputAgencies
          },
          scopedSlots: _vm._u([{
            key: "search",
            fn: function fn(_ref2) {
              var attributes = _ref2.attributes,
                events = _ref2.events;
              return [_c('input', _vm._g(_vm._b({
                ref: "refShowSelectedAgencies",
                staticClass: "vs__search"
              }, 'input', attributes, false), events))];
            }
          }, {
            key: "selected-option",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "d-flex-center justify-content-between"
              }, [_c('span', {
                staticClass: "d-block text-nowrap"
              }, [_vm._v(" " + _vm._s(data.agencyName) + " ")])])];
            }
          }, {
            key: "option",
            fn: function fn() {
              return [_c('div', {
                staticClass: "d-none"
              })];
            },
            proxy: true
          }, {
            key: "no-options",
            fn: function fn() {
              return [_c('div', {
                staticClass: "d-none"
              })];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.promotionDataToEdit.agencies,
            callback: function callback($$v) {
              _vm.$set(_vm.promotionDataToEdit, "agencies", $$v);
            },
            expression: "promotionDataToEdit.agencies"
          }
        })], 1), !_vm.readonly ? _c('b-modal', {
          attrs: {
            "id": "id-modal-promotionag-select-ag",
            "title": _vm.$t('promotionAg.agency'),
            "title-class": "font-medium-4 font-weight-bolder",
            "body-class": "p-1",
            "size": "lg"
          },
          on: {
            "shown": _vm.onShownModalSearchAndInputAgencies
          }
        }, [_c('v-select', {
          staticClass: "v-select-wrap",
          staticStyle: {
            "font-size": "1rem"
          },
          attrs: {
            "multiple": "",
            "label": "agencyName",
            "close-on-select": false,
            "options": _vm.agenciesManagerList,
            "reduce": function reduce(val) {
              return val.agencyCode || null;
            },
            "filterable": false,
            "placeholder": _vm.$t('placeholderSelect'),
            "disabled": _vm.readonly,
            "loading": _vm.loadingUsePromo
          },
          on: {
            "open": _vm.openAgenciesManager,
            "search": _vm.searchAgenciesManager
          },
          scopedSlots: _vm._u([{
            key: "search",
            fn: function fn(_ref3) {
              var attributes = _ref3.attributes,
                events = _ref3.events;
              return [_c('input', _vm._g(_vm._b({
                ref: "refSearchAndInputAgencies",
                staticClass: "vs__search"
              }, 'input', attributes, false), events))];
            }
          }, {
            key: "selected-option",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "d-flex-center justify-content-between"
              }, [_c('span', {
                staticClass: "d-block text-nowrap"
              }, [_vm._v(" " + _vm._s(data.agencyName) + " ")])])];
            }
          }, {
            key: "option",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "d-flex-center justify-content-between"
              }, [_c('span', {
                staticClass: "d-block text-nowrap"
              }, [_vm._v(" " + _vm._s(data.agencyName) + " ")]), _c('span', {
                staticClass: "d-block text-nowrap"
              }, [_vm._v(" " + _vm._s(data.agencyCode) + " ")])])];
            }
          }, {
            key: "spinner",
            fn: function fn(_ref4) {
              var loading = _ref4.loading;
              return [loading ? _c('div', {
                staticClass: "vs__spinner",
                staticStyle: {
                  "border-left-color": "rgba(88, 151, 251, 0.71)"
                }
              }) : _vm._e()];
            }
          }, {
            key: "no-options",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.promotionDataToEdit.agencies,
            callback: function callback($$v) {
              _vm.$set(_vm.promotionDataToEdit, "agencies", $$v);
            },
            expression: "promotionDataToEdit.agencies"
          }
        })], 1) : _vm._e()], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.airline')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('v-select', {
          staticStyle: {
            "font-size": "1rem"
          },
          attrs: {
            "options": _vm.airlineFilterOptionsWithAllAirlines,
            "clearable": false,
            "disabled": _vm.readonly,
            "reduce": function reduce(val) {
              return val.value;
            },
            "placeholder": _vm.$t('placeholderSelect')
          },
          scopedSlots: _vm._u([{
            key: "selected-option",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "d-flex-center justify-content-between"
              }, [_c('span', {
                staticClass: "d-block text-nowrap"
              }, [_vm._v(" " + _vm._s(data.value ? data.label : _vm.$t("promotionAg.".concat(data.label))) + " ")])])];
            }
          }, {
            key: "option",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "d-flex-center justify-content-between"
              }, [_c('span', {
                staticClass: " d-block text-nowrap"
              }, [_vm._v(" " + _vm._s(data.value ? data.label : _vm.$t("promotionAg.".concat(data.label))) + " ")])])];
            }
          }, {
            key: "spinner",
            fn: function fn(_ref5) {
              var loading = _ref5.loading;
              return [loading ? _c('div', {
                staticClass: "vs__spinner",
                staticStyle: {
                  "border-left-color": "rgba(88, 151, 251, 0.71)"
                }
              }) : _vm._e()];
            }
          }, {
            key: "no-options",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.promotionDataToEdit.airline,
            callback: function callback($$v) {
              _vm.$set(_vm.promotionDataToEdit, "airline", $$v);
            },
            expression: "promotionDataToEdit.airline"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.source')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('v-select', {
          staticStyle: {
            "font-size": "1rem"
          },
          attrs: {
            "options": _vm.sourceFilterOptionsWithAllSources,
            "clearable": false,
            "disabled": _vm.readonly,
            "reduce": function reduce(val) {
              return val.value;
            },
            "placeholder": _vm.$t('placeholderSelect')
          },
          scopedSlots: _vm._u([{
            key: "selected-option",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "d-flex-center justify-content-between"
              }, [_c('span', {
                staticClass: "d-block text-nowrap"
              }, [_vm._v(" " + _vm._s(data.value ? _vm.$te("flight.airlines.".concat(data.label)) ? "".concat(_vm.$t("flight.airlines.".concat(data.label)), " (").concat(_vm.$t("flight.sourceName.".concat(data.label)), ")") : data.label : _vm.$t("promotionAg.".concat(data.label))) + " ")])])];
            }
          }, {
            key: "option",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "d-flex-center justify-content-between"
              }, [_c('span', {
                staticClass: " d-block text-nowrap"
              }, [_vm._v(" " + _vm._s(data.value ? _vm.$te("flight.airlines.".concat(data.label)) ? "".concat(_vm.$t("flight.airlines.".concat(data.label)), " (").concat(_vm.$t("flight.sourceName.".concat(data.label)), ")") : data.label : _vm.$t("promotionAg.".concat(data.label))) + " ")])])];
            }
          }, {
            key: "spinner",
            fn: function fn(_ref6) {
              var loading = _ref6.loading;
              return [loading ? _c('div', {
                staticClass: "vs__spinner",
                staticStyle: {
                  "border-left-color": "rgba(88, 151, 251, 0.71)"
                }
              }) : _vm._e()];
            }
          }, {
            key: "no-options",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.promotionDataToEdit.source,
            callback: function callback($$v) {
              _vm.$set(_vm.promotionDataToEdit, "source", $$v);
            },
            expression: "promotionDataToEdit.source"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.codeshares')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('v-select', {
          staticClass: "v-select-wrap",
          staticStyle: {
            "font-size": "1rem"
          },
          attrs: {
            "multiple": "",
            "push-tags": "",
            "taggable": _vm.enableTaggable,
            "options": _vm.airlineFilterOptions,
            "create-option": _vm.createValidAirlineOption,
            "disabled": _vm.readonly,
            "reduce": function reduce(val) {
              return val.value;
            },
            "placeholder": _vm.$t('promotionAg.placeholder.codeshares')
          },
          scopedSlots: _vm._u([{
            key: "search",
            fn: function fn(_ref7) {
              var attributes = _ref7.attributes,
                events = _ref7.events;
              return [attributes.type === 'checkbox' ? _c('input', _vm._g(_vm._b({
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.codeshare,
                  expression: "codeshare"
                }],
                ref: "refSearchOrInputCodeshare",
                staticClass: "vs__search",
                attrs: {
                  "maxlength": "2",
                  "oninput": "this.value = this.value.toUpperCase()",
                  "type": "checkbox"
                },
                domProps: {
                  "checked": Array.isArray(_vm.codeshare) ? _vm._i(_vm.codeshare, null) > -1 : _vm.codeshare
                },
                on: {
                  "keydown": function keydown($event) {
                    if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
                    return _vm.onEnterCodeshare();
                  },
                  "change": function change($event) {
                    var $$a = _vm.codeshare,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false;
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v);
                      if ($$el.checked) {
                        $$i < 0 && (_vm.codeshare = $$a.concat([$$v]));
                      } else {
                        $$i > -1 && (_vm.codeshare = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                      }
                    } else {
                      _vm.codeshare = $$c;
                    }
                  }
                }
              }, 'input', attributes, false), events)) : attributes.type === 'radio' ? _c('input', _vm._g(_vm._b({
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.codeshare,
                  expression: "codeshare"
                }],
                ref: "refSearchOrInputCodeshare",
                staticClass: "vs__search",
                attrs: {
                  "maxlength": "2",
                  "oninput": "this.value = this.value.toUpperCase()",
                  "type": "radio"
                },
                domProps: {
                  "checked": _vm._q(_vm.codeshare, null)
                },
                on: {
                  "keydown": function keydown($event) {
                    if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
                    return _vm.onEnterCodeshare();
                  },
                  "change": function change($event) {
                    _vm.codeshare = null;
                  }
                }
              }, 'input', attributes, false), events)) : _c('input', _vm._g(_vm._b({
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.codeshare,
                  expression: "codeshare"
                }],
                ref: "refSearchOrInputCodeshare",
                staticClass: "vs__search",
                attrs: {
                  "maxlength": "2",
                  "oninput": "this.value = this.value.toUpperCase()",
                  "type": attributes.type
                },
                domProps: {
                  "value": _vm.codeshare
                },
                on: {
                  "keydown": function keydown($event) {
                    if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
                    return _vm.onEnterCodeshare();
                  },
                  "input": function input($event) {
                    if ($event.target.composing) { return; }
                    _vm.codeshare = $event.target.value;
                  }
                }
              }, 'input', attributes, false), events))];
            }
          }, {
            key: "selected-option",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "d-flex-center justify-content-between"
              }, [_c('span', {
                staticClass: "d-block text-nowrap",
                class: {
                  'font-weight-bold text-dark': !_vm.allVnAirlines.includes(data.value)
                }
              }, [_vm._v(" " + _vm._s(data.label) + " ")])])];
            }
          }, {
            key: "option",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "d-flex-center justify-content-between",
                class: {
                  'font-weight-bold text-dark': !_vm.allVnAirlines.includes(data.value)
                }
              }, [_c('span', {
                staticClass: " d-block text-nowrap"
              }, [_vm._v(" " + _vm._s(data.label) + " ")])])];
            }
          }, {
            key: "spinner",
            fn: function fn(_ref8) {
              var loading = _ref8.loading;
              return [loading ? _c('div', {
                staticClass: "vs__spinner",
                staticStyle: {
                  "border-left-color": "rgba(88, 151, 251, 0.71)"
                }
              }) : _vm._e()];
            }
          }, {
            key: "no-options",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('promotionAg.notExistCodeshare')) + " ")];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.promotionDataToEdit.codeshares,
            callback: function callback($$v) {
              _vm.$set(_vm.promotionDataToEdit, "codeshares", $$v);
            },
            expression: "promotionDataToEdit.codeshares"
          }
        })], 1)], 1)], 1), _c('b-row', {
          staticClass: "mt-3"
        }, [_c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.bookingClass')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-input', {
          attrs: {
            "lazy-formatter": "",
            "disabled": _vm.readonly,
            "formatter": _vm.trimInput,
            "placeholder": _vm.$t('promotionAg.placeholder.bookingClass')
          },
          model: {
            value: _vm.promotionDataToEdit.bookingClass,
            callback: function callback($$v) {
              _vm.$set(_vm.promotionDataToEdit, "bookingClass", $$v);
            },
            expression: "promotionDataToEdit.bookingClass"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.flightTrip')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('v-select', {
          staticClass: "v-select-wrap",
          staticStyle: {
            "font-size": "1rem"
          },
          attrs: {
            "multiple": "",
            "taggable": "",
            "filterable": false,
            "disabled": _vm.readonly,
            "placeholder": _vm.$t('promotionAg.placeholder.flightTrip')
          },
          scopedSlots: _vm._u([{
            key: "search",
            fn: function fn(_ref9) {
              var attributes = _ref9.attributes,
                events = _ref9.events;
              return [_c('input', _vm._g(_vm._b({
                ref: "refShowInputFlightTrips",
                staticClass: "vs__search",
                attrs: {
                  "oninput": "this.value = this.value.replaceAll(' ','').toUpperCase()"
                },
                on: {
                  "input": function input($event) {
                    return _vm.onInputFlightTrip();
                  },
                  "keydown": [function ($event) {
                    if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
                    return _vm.onEnterFlightTrip();
                  }, function ($event) {
                    if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "space", 32, $event.key, [" ", "Spacebar"])) { return null; }
                    return _vm.onOpenModalInputFlightTrips();
                  }]
                }
              }, 'input', attributes, false), events))];
            }
          }, {
            key: "selected-option",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "d-flex-center justify-content-between"
              }, [_c('span', {
                staticClass: "d-block text-nowrap"
              }, [_vm._v(" " + _vm._s(data.label) + " ")])])];
            }
          }, {
            key: "option",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "d-flex-center justify-content-between"
              }, [_c('span', {
                staticClass: "d-block text-nowrap"
              }, [_vm._v(" " + _vm._s(data.label) + " ")])])];
            }
          }, {
            key: "no-options",
            fn: function fn() {
              return [!_vm.readonly ? _c('div', {
                staticClass: "text-left"
              }, [_c('p', [_c('span', {
                staticClass: "text-danger"
              }, [_c('em', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.inputflightTrip')) + " ")])]), _c('br'), _c('b', [_vm._v(_vm._s(_vm.$t('promotionAg.placeholder.flightTrip')))]), _c('br')]), _c('p', {
                staticClass: "text-danger"
              }, [_c('em', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.orSearchAirports')) + " ")])]), _c('p', {
                staticClass: "text-center"
              }, [_c('b-button', {
                directives: [{
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: 'rgba(113, 102, 240, 0.15)',
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: {
                    "400": true
                  }
                }],
                attrs: {
                  "variant": "outline-primary"
                },
                on: {
                  "click": function click($event) {
                    $event.preventDefault();
                    return _vm.onOpenModalInputFlightTrips();
                  }
                }
              }, [_c('feather-icon', {
                staticClass: "mr-50",
                attrs: {
                  "icon": "SearchIcon"
                }
              }), _c('span', {
                staticClass: "align-middle"
              }, [_vm._v(" " + _vm._s(_vm.$t('search')) + " ")])], 1)], 1)]) : _vm._e()];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.promotionDataToEdit.flightTrips,
            callback: function callback($$v) {
              _vm.$set(_vm.promotionDataToEdit, "flightTrips", $$v);
            },
            expression: "promotionDataToEdit.flightTrips"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.flightTypes')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('v-select', {
          staticStyle: {
            "font-size": "1rem"
          },
          attrs: {
            "options": _vm.flightTypeOptionsForPromotionAg,
            "reduce": function reduce(val) {
              return val.value;
            },
            "filterable": false,
            "disabled": _vm.readonly,
            "placeholder": _vm.$t('placeholderSelect')
          },
          scopedSlots: _vm._u([{
            key: "selected-option",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "d-flex-center justify-content-between"
              }, [_c('span', {
                staticClass: "d-block text-nowrap"
              }, [_vm._v(" " + _vm._s(_vm.$t(data.label)) + " ")])])];
            }
          }, {
            key: "option",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "d-flex-center justify-content-between"
              }, [_c('span', {
                staticClass: "d-block text-nowrap"
              }, [_vm._v(" " + _vm._s(_vm.$t(data.label)) + " ")])])];
            }
          }, {
            key: "no-options",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.promotionDataToEdit.flightType,
            callback: function callback($$v) {
              _vm.$set(_vm.promotionDataToEdit, "flightType", $$v);
            },
            expression: "promotionDataToEdit.flightType"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.fareBasisCode')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-input', {
          attrs: {
            "lazy-formatter": "",
            "disabled": _vm.readonly,
            "formatter": _vm.trimInput,
            "placeholder": _vm.$t('promotionAg.placeholder.fareBasisCode')
          },
          model: {
            value: _vm.promotionDataToEdit.fareBasisCode,
            callback: function callback($$v) {
              _vm.$set(_vm.promotionDataToEdit, "fareBasisCode", $$v);
            },
            expression: "promotionDataToEdit.fareBasisCode"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.flightStartTime')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-datepicker', {
          attrs: {
            "disabled": _vm.readonly,
            "locale": _vm.$i18n.locale,
            "placeholder": _vm.$t('placeholderSelect'),
            "date-format-options": {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit'
            },
            "min": new Date(),
            "max": _vm.promotionDataToEdit.flightEndTime ? new Date(_vm.promotionDataToEdit.flightEndTime) : null,
            "show-decade-nav": "",
            "hide-header": "",
            "boundary": "window",
            "no-flip": ""
          },
          model: {
            value: _vm.promotionDataToEdit.flightStartTime,
            callback: function callback($$v) {
              _vm.$set(_vm.promotionDataToEdit, "flightStartTime", $$v);
            },
            expression: "promotionDataToEdit.flightStartTime"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.flightEndTime')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-datepicker', {
          attrs: {
            "disabled": _vm.readonly,
            "locale": _vm.$i18n.locale,
            "placeholder": _vm.$t('placeholderSelect'),
            "date-format-options": {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit'
            },
            "min": _vm.promotionDataToEdit.flightStartTime ? new Date(_vm.promotionDataToEdit.flightStartTime) : new Date(),
            "show-decade-nav": "",
            "hide-header": "",
            "boundary": "window",
            "no-flip": ""
          },
          model: {
            value: _vm.promotionDataToEdit.flightEndTime,
            callback: function callback($$v) {
              _vm.$set(_vm.promotionDataToEdit, "flightEndTime", $$v);
            },
            expression: "promotionDataToEdit.flightEndTime"
          }
        })], 1)], 1)], 1)], 1)], 1)], 1), _c('div', {
          staticClass: "d-flex justify-content-center"
        }, [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          class: !_vm.readonly ? 'mr-50' : '',
          attrs: {
            "variant": "outline-danger"
          },
          on: {
            "click": function click($event) {
              return _vm.backHandle();
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "RotateCcwIcon"
          }
        }), _c('span', {
          staticClass: "align-middle"
        }, [_vm._v(_vm._s(_vm.$t('customer.back')))])], 1), !_vm.readonly ? _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "ml-50",
          attrs: {
            "variant": "outline-primary",
            "disabled": _vm.readonly || invalid || !_vm.canAccess('customer.updateCustomer')
          },
          on: {
            "click": function click($event) {
              return _vm.nextHandle();
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "RefreshCwIcon"
          }
        }), _c('span', {
          staticClass: "align-middle"
        }, [_vm._v(" " + _vm._s(_vm.$t('customer.save')) + " ")])], 1) : _vm._e()], 1)];
      }
    }])
  }), !_vm.readonly ? _c('ModalInputFlightTrips', {
    on: {
      "addTrip": function addTrip($event) {
        return _vm.addTrip($event, _vm.promotionDataToEdit);
      }
    }
  }) : _vm._e()], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }