export function onOpenModalInputFlightTrips() {
  this.$root.$emit(
    'bv::show::modal',
    'id-modal-promotionag-select-flight-trips',
  )
}

export function addTrip(trip, promotionData) {
  if (!promotionData.flightTrips.includes(trip)) {
    promotionData.flightTrips.push(trip)
  }
}

export function onInputFlightTrip() {
  // Get the current input value
  const inputValue = this.$refs.refShowInputFlightTrips.value

  // Check if the input value has exactly three characters
  if (inputValue.length === 3) {
    // Add a hyphen at the end of the input value
    this.$refs.refShowInputFlightTrips.value = `${inputValue}-`
  }

  // Set focus to the input field
  this.$refs.refShowInputFlightTrips.focus()
}

export function onEnterFlightTrip() {
  this.$nextTick(() => {
    this.$refs.refShowInputFlightTrips.value = ''
    this.$refs.refShowInputFlightTrips.focus()
  })
}
