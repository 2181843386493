<template>
  <b-overlay
    :show="loading"
    rounded="sm"
    no-fade
    variant="transparent"
    :opacity="0.5"
  >
    <section>
      <validation-observer
        #default="{ invalid }"
        ref="refFormObserver"
      >
        <!-- SECTION Form -->
        <b-form>
          <!-- SECTION Card agency basic info -->
          <b-card
            header-tag="header"
            border-variant="info"
            header-bg-variant="light-info"
            header-class="py-1"
            class="border mt-1"
          >
            <!-- ANCHOR header -->
            <template #header>
              <div class="d-flex align-items-center justify-content-between w-100">
                <h6 class="m-0">
                  {{ readonly ? $t('promotionAg.edit.title') : $t('promotionAg.edit.titleEdit') }}
                  <b-badge
                    v-if="false"
                    variant="primary"
                    class="py-50"
                  >
                    {{ promotionDataToEdit.code }}
                  </b-badge>
                </h6>

                <b-button
                  v-if="readonly && canEdit"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  class="py-75"
                  :disabled="!canAccess('customer.updateCustomer')"
                  @click="handleEdit"
                >
                  <feather-icon
                    icon="RefreshCwIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">{{ $t('customer.edit') }}</span>
                </b-button>
                <b-button
                  v-if="!readonly"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-danger"
                  class="py-75"
                  :disabled="!canAccess('customer.updateCustomer')"
                  @click="handleCancelEdit"
                >
                  <feather-icon
                    icon="XIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">{{ $t('customer.cancel') }}</span>
                </b-button>
              </div>
            </template>
            <b-card-body class="p-0">
              <b-row class="mt-1 mb-3">
                <!-- ANCHOR Code -->
                <b-col md="4">
                  <b-form-group>
                    <template #label>
                      <div>
                        {{ $t('promotionAg.code') }}
                      </div>
                    </template>
                    <b-form-input
                      v-model="promotionDataToEdit.code"
                      maxlength="9"
                      lazy-formatter
                      :disabled="true"
                      :formatter="trimInput"
                      :placeholder="$t('promotionAg.placeholder.code')"
                    />

                  </b-form-group>
                </b-col>

                <!-- ANCHOR Name -->
                <b-col md="4">
                  <validation-provider
                    #default="validationContext"
                    :name="$t('promotionAg.name')"
                    rules=""
                  >
                    <b-form-group>
                      <template #label>
                        <div>
                          {{ $t('promotionAg.name') }}
                        </div>
                      </template>
                      <b-form-input
                        v-model="promotionDataToEdit.name"
                        maxlength="100"
                        lazy-formatter
                        :disabled="readonly"
                        :formatter="trimInput"
                        :placeholder="$t('promotionAg.placeholder.name')"
                        :state="getValidationState(validationContext) === false ? false : null"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR promotionType -->
                <b-col md="4">
                  <b-form-group>
                    <template #label>
                      <div>
                        {{ $t('promotionAg.promotionType') }}
                      </div>
                    </template>
                    <v-select
                      v-model="promotionDataToEdit.paidType"
                      style="font-size: 1rem;"
                      :options="typeOfDiscountPaidOptions"
                      :clearable="false"
                      :disabled="readonly"
                      :reduce="val => val.value"
                    >
                      <template #option="data">
                        <span>
                          {{ $t(`promotionAg.${data.label}`) }}
                        </span>
                      </template>

                      <template #selected-option="data">
                        <span>
                          {{ $t(`promotionAg.${data.label}`) }}
                        </span>
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>

                <!-- ANCHOR discountAmount -->
                <b-col
                  v-if="promotionDataToEdit.discountType === 'VND'"
                  md="2"
                  style="min-width: 20%;"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('promotionAg.discount')"
                    rules="required"
                  >
                    <b-form-group>
                      <template #label>
                        <div>
                          {{ $t('promotionAg.discount') }}
                          <span class="text-danger">(*)</span>
                        </div>
                      </template>
                      <b-form-input
                        v-model="promotionDataToEdit.discountAmount"
                        v-remove-non-numeric-chars.allNumber
                        number
                        type="number"
                        :state="getValidationState(validationContext) === false ? false : null"
                        maxlength="30"
                        lazy-formatter
                        :disabled="readonly"
                        :formatter="trimInput"
                        :placeholder="$t('promotionAg.placeholder.discountAmount')"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR discountPercent -->
                <b-col
                  v-else
                  md="2"
                  style="min-width: 20%;"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('promotionAg.discount')"
                    rules="required|maxValue:100|min_value:0"
                  >
                    <b-form-group>
                      <template #label>
                        <div>
                          {{ $t('promotionAg.discount') }}
                          <span class="text-danger">(*)</span>
                        </div>
                      </template>
                      <b-form-input
                        v-model="promotionDataToEdit.discountPercent"
                        v-remove-non-numeric-chars.allNumber
                        type="number"
                        number
                        :state="getValidationState(validationContext) === false ? false : null"
                        max="100"
                        lazy-formatter
                        :disabled="readonly"
                        :formatter="trimInput"
                        :placeholder="$t('promotionAg.placeholder.discountPercent')"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR discountType -->
                <b-col md>
                  <b-form-group>
                    <template #label>
                      <div style="opacity: 0;">
                        {{ "-" }}
                      </div>
                    </template>
                    <v-select
                      v-model="promotionDataToEdit.discountType"
                      style="font-size: 1rem;"
                      :options="typeOfDiscountOptions"
                      :clearable="false"
                      :disabled="readonly"
                      :reduce="val => val.value"
                    />
                  </b-form-group>
                </b-col>

                <!-- ANCHOR Start date -->
                <b-col md="4">
                  <validation-provider
                    #default="validationContext"
                    name="Start date"
                    rules="required"
                  >
                    <b-form-group>
                      <template #label>
                        <div>
                          {{ $t('promotionAg.startTime') }}
                          <span class="text-danger">(*)</span>
                        </div>
                      </template>
                      <b-form-datepicker
                        v-model="promotionDataToEdit.startTime"
                        :disabled="readonly"
                        :locale="$i18n.locale"
                        :placeholder="$t('placeholderSelect')"
                        :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                        :min="new Date()"
                        :max="promotionDataToEdit.endTime ? new Date(promotionDataToEdit.endTime) : null"
                        show-decade-nav
                        hide-header
                        boundary="window"
                        no-flip
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR End date -->
                <b-col md="4">
                  <validation-provider
                    #default="validationContext"
                    name="End date"
                    rules="required"
                  >
                    <b-form-group>
                      <template #label>
                        <div>
                          {{ $t('promotionAg.endTime') }}
                          <span class="text-danger">(*)</span>
                        </div>
                      </template>
                      <b-form-datepicker
                        v-model="promotionDataToEdit.endTime"
                        reset-button
                        close-button
                        :disabled="readonly"
                        :locale="$i18n.locale"
                        :placeholder="$t('placeholderSelect')"
                        :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                        :min="promotionDataToEdit.startTime ? new Date(promotionDataToEdit.startTime) : new Date()"
                        show-decade-nav
                        hide-header
                        boundary="window"
                        no-flip
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>

              <b-row>
                <!-- ANCHOR minimum Passenger -->
                <b-col md="4">
                  <validation-provider
                    #default="validationContext"
                    :name="$t('promotionAg.minimumPassenger')"
                    rules=""
                  >
                    <b-form-group>
                      <template #label>
                        <div>
                          {{ $t('promotionAg.minimumPassenger') }}
                        </div>
                      </template>
                      <b-form-input
                        v-model="promotionDataToEdit.minimumPassenger"
                        v-remove-non-numeric-chars.allNumber
                        number
                        maxlength="5"
                        lazy-formatter
                        :disabled="readonly"
                        :formatter="trimInput"
                        :placeholder="$t('promotionAg.placeholder.minimumPassenger')"
                        :state="getValidationState(validationContext) === false ? false : null"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR Quota -->
                <b-col md="4">
                  <b-form-group>
                    <template #label>
                      <div>
                        {{ $t('promotionAg.quotaAmount') }}
                      </div>
                    </template>
                    <b-form-input
                      v-model="promotionDataToEdit.quotaAmount"
                      v-remove-non-numeric-chars.allNumber
                      number
                      type="number"
                      maxlength="30"
                      lazy-formatter
                      :disabled="readonly"
                      :formatter="trimInput"
                      :placeholder="$t('promotionAg.placeholder.quotaAmount')"
                    />
                  </b-form-group>
                </b-col>

                <!-- ANCHOR quotaCounter -->
                <b-col md="4">
                  <b-form-group>
                    <template #label>
                      <div>
                        {{ $t('promotionAg.quotaCounter') }}
                      </div>
                    </template>
                    <b-form-input
                      v-model="promotionDataToEdit.quotaCounter"
                      v-remove-non-numeric-chars.allNumber
                      type="number"
                      number
                      maxlength="30"
                      lazy-formatter
                      :disabled="readonly"
                      :formatter="trimInput"
                      :placeholder="$t('promotionAg.placeholder.quotaCounter')"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <!-- ANCHOR agencies -->
                <b-col md="4">
                  <b-form-group>
                    <template #label>
                      <div>
                        {{ $t('promotionAg.agency') }}
                      </div>
                    </template>
                    <v-select
                      v-model="promotionDataToEdit.agencies"
                      style="font-size: 1rem;"
                      multiple
                      label="agencyName"
                      :disabled="readonly"
                      :options="agenciesManagerList"
                      :reduce="val => val.agencyCode"
                      :filterable="false"
                      :placeholder="$t('promotionAg.allAgencies')"
                      class="v-select-wrap"
                      @open="onFocusSearchAndInputAgencies"
                    >
                      <template #search="{ attributes, events }">
                        <input
                          ref="refShowSelectedAgencies"
                          v-bind="attributes"
                          class="vs__search"
                          v-on="events"
                        >
                      </template>
                      <template #selected-option="data">
                        <div class="d-flex-center justify-content-between">
                          <span
                            class="d-block text-nowrap"
                          >
                            {{ data.agencyName }}
                          </span>
                        </div>
                      </template>
                      <template #option>
                        <div class="d-none" />
                      </template>
                      <template #no-options>
                        <div class="d-none" />
                      </template>
                    </v-select>
                  </b-form-group>

                  <b-modal
                    v-if="!readonly"
                    id="id-modal-promotionag-select-ag"
                    :title="$t('promotionAg.agency')"
                    title-class="font-medium-4 font-weight-bolder"
                    body-class="p-1"
                    size="lg"
                    @shown="onShownModalSearchAndInputAgencies"
                  >
                    <v-select
                      v-model="promotionDataToEdit.agencies"
                      style="font-size: 1rem;"
                      multiple
                      label="agencyName"
                      :close-on-select="false"
                      :options="agenciesManagerList"
                      :reduce="val => val.agencyCode || null"
                      :filterable="false"
                      :placeholder="$t('placeholderSelect')"
                      :disabled="readonly"
                      :loading="loadingUsePromo"
                      class="v-select-wrap"
                      @open="openAgenciesManager"
                      @search="searchAgenciesManager"
                    >
                      <template #search="{ attributes, events }">
                        <input
                          ref="refSearchAndInputAgencies"
                          v-bind="attributes"
                          class="vs__search"
                          v-on="events"
                        >
                      </template>
                      <template #selected-option="data">
                        <div class="d-flex-center justify-content-between">
                          <span
                            class="d-block text-nowrap"
                          >
                            {{ data.agencyName }}
                          </span>
                        </div>
                      </template>
                      <template #option="data">
                        <div class="d-flex-center justify-content-between">
                          <span
                            class="d-block text-nowrap"
                          >
                            {{ data.agencyName }}
                          </span>
                          <span
                            class="d-block text-nowrap"
                          >
                            {{ data.agencyCode }}
                          </span>
                        </div>
                      </template>
                      <template #spinner="{ loading }">
                        <div
                          v-if="loading"
                          style="border-left-color: rgba(88, 151, 251, 0.71)"
                          class="vs__spinner"
                        />
                      </template>
                      <template #no-options>
                        {{ $t('noOptions') }}
                      </template>
                    </v-select>
                  </b-modal>
                </b-col>

                <!-- ANCHOR Airline -->
                <b-col md="4">
                  <b-form-group>
                    <template #label>
                      <div>
                        {{ $t('promotionAg.airline') }}
                      </div>
                    </template>
                    <v-select
                      v-model="promotionDataToEdit.airline"
                      style="font-size: 1rem;"
                      :options="airlineFilterOptionsWithAllAirlines"
                      :clearable="false"
                      :disabled="readonly"
                      :reduce="val => val.value"
                      :placeholder="$t('placeholderSelect')"
                    >
                      <template #selected-option="data">
                        <div class="d-flex-center justify-content-between">
                          <span
                            class="d-block text-nowrap"
                          >
                            {{ data.value ? data.label : $t(`promotionAg.${data.label}`) }}
                          </span>
                        </div>
                      </template>
                      <template #option="data">
                        <div class="d-flex-center justify-content-between">
                          <span
                            class=" d-block text-nowrap"
                          >
                            {{ data.value ? data.label : $t(`promotionAg.${data.label}`) }}
                          </span>
                        </div>
                      </template>
                      <template #spinner="{ loading }">
                        <div
                          v-if="loading"
                          style="border-left-color: rgba(88, 151, 251, 0.71)"
                          class="vs__spinner"
                        />
                      </template>
                      <template #no-options>
                        {{ $t('noOptions') }}
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>

                <!-- ANCHOR Source -->
                <b-col md="4">
                  <b-form-group>
                    <template #label>
                      <div>
                        {{ $t('promotionAg.source') }}
                      </div>
                    </template>
                    <v-select
                      v-model="promotionDataToEdit.source"
                      style="font-size: 1rem;"
                      :options="sourceFilterOptionsWithAllSources"
                      :clearable="false"
                      :disabled="readonly"
                      :reduce="val => val.value"
                      :placeholder="$t('placeholderSelect')"
                    >
                      <template #selected-option="data">
                        <div class="d-flex-center justify-content-between">
                          <span
                            class="d-block text-nowrap"
                          >
                            {{ data.value
                              ? ($te(`flight.airlines.${data.label}`)
                                ? `${$t(`flight.airlines.${data.label}`)} (${$t(`flight.sourceName.${data.label}`)})`
                                : data.label )
                              : $t(`promotionAg.${data.label}`) }}
                          </span>
                        </div>
                      </template>
                      <template #option="data">
                        <div class="d-flex-center justify-content-between">
                          <span
                            class=" d-block text-nowrap"
                          >
                            {{ data.value
                              ? ($te(`flight.airlines.${data.label}`)
                                ? `${$t(`flight.airlines.${data.label}`)} (${$t(`flight.sourceName.${data.label}`)})`
                                : data.label )
                              : $t(`promotionAg.${data.label}`) }}
                          </span>
                        </div>
                      </template>
                      <template #spinner="{ loading }">
                        <div
                          v-if="loading"
                          style="border-left-color: rgba(88, 151, 251, 0.71)"
                          class="vs__spinner"
                        />
                      </template>
                      <template #no-options>
                        {{ $t('noOptions') }}
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>

                <!-- ANCHOR Codeshares -->
                <b-col md="4">
                  <b-form-group>
                    <template #label>
                      <div>
                        {{ $t('promotionAg.codeshares') }}
                      </div>
                    </template>
                    <v-select
                      v-model="promotionDataToEdit.codeshares"
                      style="font-size: 1rem;"
                      multiple
                      push-tags
                      :taggable="enableTaggable"
                      :options="airlineFilterOptions"
                      :create-option="createValidAirlineOption"
                      :disabled="readonly"
                      :reduce="val => val.value"
                      :placeholder="$t('promotionAg.placeholder.codeshares')"
                      class="v-select-wrap"
                    >
                      <template #search="{ attributes, events }">
                        <input
                          ref="refSearchOrInputCodeshare"
                          v-model="codeshare"
                          v-bind="attributes"
                          maxlength="2"
                          class="vs__search"
                          oninput="this.value = this.value.toUpperCase()"
                          v-on="events"
                          @keydown.enter="onEnterCodeshare()"
                        >
                      </template>
                      <template #selected-option="data">
                        <div class="d-flex-center justify-content-between">
                          <span
                            class="d-block text-nowrap"
                            :class="{ 'font-weight-bold text-dark': !allVnAirlines.includes(data.value) }"
                          >
                            {{ data.label }}
                          </span>
                        </div>
                      </template>
                      <template #option="data">
                        <div
                          class="d-flex-center justify-content-between"
                          :class="{ 'font-weight-bold text-dark': !allVnAirlines.includes(data.value) }"
                        >
                          <span
                            class=" d-block text-nowrap"
                          >
                            {{ data.label }}
                          </span>
                        </div>
                      </template>
                      <template #spinner="{ loading }">
                        <div
                          v-if="loading"
                          style="border-left-color: rgba(88, 151, 251, 0.71)"
                          class="vs__spinner"
                        />
                      </template>
                      <template #no-options>
                        {{ $t('promotionAg.notExistCodeshare') }}
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row class="mt-3">
                <b-col md="4">
                  <b-form-group>
                    <template #label>
                      <div>
                        {{ $t('promotionAg.bookingClass') }}
                      </div>
                    </template>
                    <b-form-input
                      v-model="promotionDataToEdit.bookingClass"
                      lazy-formatter
                      :disabled="readonly"
                      :formatter="trimInput"
                      :placeholder="$t('promotionAg.placeholder.bookingClass')"
                    />
                  </b-form-group>
                </b-col>

                <!-- ANCHOR Flight trips -->
                <b-col md="4">
                  <b-form-group>
                    <template #label>
                      <div>
                        {{ $t('promotionAg.flightTrip') }}
                      </div>
                    </template>
                    <v-select
                      v-model="promotionDataToEdit.flightTrips"
                      style="font-size: 1rem;"
                      multiple
                      taggable
                      :filterable="false"
                      :disabled="readonly"
                      :placeholder="$t('promotionAg.placeholder.flightTrip')"
                      class="v-select-wrap"
                    >
                      <template #search="{ attributes, events }">
                        <input
                          ref="refShowInputFlightTrips"
                          v-bind="attributes"
                          class="vs__search"
                          oninput="this.value = this.value.replaceAll(' ','').toUpperCase()"
                          v-on="events"
                          @input="onInputFlightTrip()"
                          @keydown.enter="onEnterFlightTrip()"
                          @keydown.space="onOpenModalInputFlightTrips()"
                        >
                      </template>
                      <template #selected-option="data">
                        <div class="d-flex-center justify-content-between">
                          <span
                            class="d-block text-nowrap"
                          >
                            {{ data.label }}
                          </span>
                        </div>
                      </template>
                      <template #option="data">
                        <div class="d-flex-center justify-content-between">
                          <span
                            class="d-block text-nowrap"
                          >
                            {{ data.label }}
                          </span>
                        </div>
                      </template>
                      <template #no-options>
                        <div
                          v-if="!readonly"
                          class="text-left"
                        >
                          <p>
                            <span class="text-danger">
                              <em>
                                {{ $t('promotionAg.inputflightTrip') }}
                              </em>
                            </span>
                            <br>
                            <b>{{ $t('promotionAg.placeholder.flightTrip') }}</b><br>
                          </p>
                          <p class="text-danger">
                            <em>
                              {{ $t('promotionAg.orSearchAirports') }}
                            </em>
                          </p>
                          <p class="text-center">
                            <b-button
                              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                              variant="outline-primary"
                              @click.prevent="onOpenModalInputFlightTrips()"
                            >
                              <feather-icon
                                icon="SearchIcon"
                                class="mr-50"
                              />
                              <span class="align-middle"> {{ $t('search') }} </span>
                            </b-button>
                          </p>
                        </div>
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>

                <!-- ANCHOR Flight types -->
                <b-col md="4">
                  <b-form-group>
                    <template #label>
                      <div>
                        {{ $t('promotionAg.flightTypes') }}
                      </div>
                    </template>
                    <v-select
                      v-model="promotionDataToEdit.flightType"
                      style="font-size: 1rem;"
                      :options="flightTypeOptionsForPromotionAg"
                      :reduce="val => val.value"
                      :filterable="false"
                      :disabled="readonly"
                      :placeholder="$t('placeholderSelect')"
                    >
                      <template #selected-option="data">
                        <div class="d-flex-center justify-content-between">
                          <span
                            class="d-block text-nowrap"
                          >
                            {{ $t(data.label) }}
                          </span>
                        </div>
                      </template>
                      <template #option="data">
                        <div class="d-flex-center justify-content-between">
                          <span
                            class="d-block text-nowrap"
                          >
                            {{ $t(data.label) }}
                          </span>
                        </div>
                      </template>
                      <template #no-options>
                        {{ $t('noOptions') }}
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group>
                    <template #label>
                      <div>
                        {{ $t('promotionAg.fareBasisCode') }}
                      </div>
                    </template>
                    <b-form-input
                      v-model="promotionDataToEdit.fareBasisCode"
                      lazy-formatter
                      :disabled="readonly"
                      :formatter="trimInput"
                      :placeholder="$t('promotionAg.placeholder.fareBasisCode')"
                    />
                  </b-form-group>
                </b-col>

                <!-- ANCHOR Flight start date -->
                <b-col md="4">
                  <b-form-group>
                    <template #label>
                      <div>
                        {{ $t('promotionAg.flightStartTime') }}
                      </div>
                    </template>
                    <b-form-datepicker
                      v-model="promotionDataToEdit.flightStartTime"
                      :disabled="readonly"
                      :locale="$i18n.locale"
                      :placeholder="$t('placeholderSelect')"
                      :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                      :min="new Date()"
                      :max="promotionDataToEdit.flightEndTime ? new Date(promotionDataToEdit.flightEndTime) : null"
                      show-decade-nav
                      hide-header
                      boundary="window"
                      no-flip
                    />

                  </b-form-group>
                </b-col>

                <!-- ANCHOR Flight end date -->
                <b-col md="4">
                  <b-form-group>
                    <template #label>
                      <div>
                        {{ $t('promotionAg.flightEndTime') }}
                      </div>
                    </template>
                    <b-form-datepicker
                      v-model="promotionDataToEdit.flightEndTime"
                      :disabled="readonly"
                      :locale="$i18n.locale"
                      :placeholder="$t('placeholderSelect')"
                      :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                      :min="promotionDataToEdit.flightStartTime ? new Date(promotionDataToEdit.flightStartTime) : new Date()"
                      show-decade-nav
                      hide-header
                      boundary="window"
                      no-flip
                    />

                  </b-form-group>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        <!-- !SECTION -->

        </b-form>
        <!-- !SECTION -->

        <!--  ANCHOR Button Actions -->
        <div class="d-flex justify-content-center">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-danger"
            :class="!readonly ? 'mr-50' : ''"
            @click="backHandle()"
          >
            <feather-icon
              icon="RotateCcwIcon"
              class="mr-50"
            />
            <span class="align-middle">{{ $t('customer.back') }}</span>
          </b-button>
          <b-button
            v-if="!readonly"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            class="ml-50"
            :disabled="readonly || invalid || !canAccess('customer.updateCustomer')"
            @click="nextHandle()"
          >
            <feather-icon
              icon="RefreshCwIcon"
              class="mr-50"
            />
            <span class="align-middle"> {{ $t('customer.save') }} </span>
          </b-button>
        </div>
      </validation-observer>

      <ModalInputFlightTrips
        v-if="!readonly"
        @addTrip="addTrip($event, promotionDataToEdit)"
      />
    </section>
  </b-overlay>
</template>

<script>
import { computed, onUnmounted, ref } from '@vue/composition-api'
import {
  BBadge,
  BButton,
  BOverlay,
  BCard,
  BCardBody,
  BCol,
  BForm,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BRow,
  BModal,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import moment from 'moment'

import router from '@/router'
import {
  typeOfDiscountOptions, typeOfDiscountPaidOptions, flightTypeOptionsForPromotionAg,
} from '@/constants/selectOptions'
import store from '@/store'

import { getDifference } from '@core/utils/utils'
import formValidation from '@core/comp-functions/forms/form-validation'
import { trimInput } from '@core/comp-functions/forms/formatter-input'
import { avatarText } from '@core/utils/filter'

import ModalInputFlightTrips from '@promotionAg/ModalInputFlightTrips'
import promotionAgStoreModule from '@promotionAg/promotionAgStoreModule'
import usePromotionAgAirlines from '@promotionAg/usePromotionAgAirlines'
import usePromotionAgCodeshare from '@promotionAg/usePromotionAgCodeshare'
import {
  onFocusSearchAndInputAgencies,
  onShownModalSearchAndInputAgencies,
} from '@promotionAg/usePromotionAgAgency'
import {
  onOpenModalInputFlightTrips,
  addTrip,
  onInputFlightTrip,
  onEnterFlightTrip,
} from '@promotionAg/usePromotionAgTrips'
import usePromotionAgHandle from '@promotionAg/usePromotionAgHandle'
import {
  email, integer, isUnique,
  max,
  min,
  phoneNumber,
  required,
} from '@validations'

export default {
  components: {
    BForm,
    BBadge,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BOverlay,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BModal,
    vSelect,
    BFormDatepicker,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

    ModalInputFlightTrips,
  },

  setup() {
    const promotionData = ref(null)
    const loading = ref(true)
    const PROMOTION_AG_APP_STORE_MODULE_NAME = 'app-promotionAg'

    // Register module
    if (!store.hasModule(PROMOTION_AG_APP_STORE_MODULE_NAME)) {
      store.registerModule(PROMOTION_AG_APP_STORE_MODULE_NAME, promotionAgStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PROMOTION_AG_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(PROMOTION_AG_APP_STORE_MODULE_NAME)
      }
    })

    const {
      allVnAirlines,
      airlineFilterOptions,
      airlineFilterOptionsWithAllAirlines,
      sourceFilterOptionsWithAllSources,
      createValidAirlineOption,
    } = usePromotionAgAirlines()

    const {
      codeshare,
      enableTaggable,
      onEnterCodeshare,
    } = usePromotionAgCodeshare()

    const promotionDataToEdit = ref({})
    const resetPromotionData = () => {
      promotionDataToEdit.value = JSON.parse(JSON.stringify(promotionData.value))
    }

    store.dispatch('app-promotionAg/getPromotionById', { id: router.currentRoute.params.id })
      .then(({ data }) => {
        const normalizeData = {
          ...data,
          discount: data.discountPercent || data.discountAmount,
          discountType: data.discountPercent > 0 ? '%' : 'VND',
          flightTrips: data.flightTrips || [],
        }
        promotionData.value = normalizeData
        resetPromotionData()

        const allAirlines = [...allVnAirlines]
        normalizeData.codeshares.forEach(code => {
          if (!allAirlines.includes(code)) {
            allAirlines.push(code)
          }
        })
        airlineFilterOptions.value = allAirlines.map(value => (createValidAirlineOption(value)))
      })
      .catch(error => {
        if (error) {
          promotionData.value = undefined
        }
      })
      .finally(() => {
        loading.value = false
      })

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetPromotionData)
    const {
      updatePromotion,
      agenciesManagerList,
      openAgenciesManager,
      searchAgenciesManager,
      loading: loadingUsePromo,
    } = usePromotionAgHandle()

    const readonly = ref(true)
    const isReset = ref(false)

    const meData = computed(() => store.getters['userStore/getMeData'])
    const canEdit = computed(() => {
      if (!promotionData.value) return true
      const isType = ['OPE', 'ADM'].includes(meData.value?.type)
      const isStarted = moment().isAfter(moment(promotionData.value.startTime))
      return isType && !isStarted
    })

    function nextHandle() {
      const dataToUpdate = getDifference(
        promotionDataToEdit.value,
        promotionData,
      )
      this.$bvModal.show('modal-api-loading')
      updatePromotion(promotionDataToEdit.value, dataToUpdate).then(() => {
        readonly.value = true
      }).finally(() => {
        this.$bvModal.hide('modal-api-loading')
      })
    }

    const onCancel = () => {
      resetForm()
      router.go(-1)
    }

    function handleEdit() {
      readonly.value = false
      isReset.value = false
    }

    function handleCancelEdit() {
      readonly.value = true
      isReset.value = true
    }

    function backHandle() {
      router.go(-1)
    }

    function clearHandle() {
      this.$bvModal
        .msgBoxConfirm(this.$t('modal.clearForm'), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) resetForm()
        })
    }

    openAgenciesManager()

    return {
      promotionDataToEdit,
      refFormObserver,
      getValidationState,
      avatarText,

      // select options
      typeOfDiscountOptions,
      typeOfDiscountPaidOptions,
      flightTypeOptionsForPromotionAg,

      agenciesManagerList,
      openAgenciesManager,
      searchAgenciesManager,
      loadingUsePromo,
      // Validations
      required,
      min,
      max,
      email,
      integer,
      isUnique,
      phoneNumber,

      readonly,
      canEdit,
      isReset,
      loading,
      // Methods
      onCancel,
      handleEdit,
      handleCancelEdit,

      backHandle,
      nextHandle,
      clearHandle,
      trimInput,

      /* returned by usePromotionAgAirlines() */
      allVnAirlines,
      airlineFilterOptions,
      airlineFilterOptionsWithAllAirlines,
      sourceFilterOptionsWithAllSources,
      createValidAirlineOption,
      /* !returned by usePromotionAgAirlines() */

      /* returned by usePromotionAgCodeshare() */
      codeshare,
      enableTaggable,
      onEnterCodeshare,
      /* !returned by usePromotionAgCodeshare() */

      /* imported from '@promotionAg/usePromotionAgAgency' */
      // search and input Agencies
      onFocusSearchAndInputAgencies,
      onShownModalSearchAndInputAgencies,
      /* !imported from '@promotionAg/usePromotionAgAgency' */

      /* imported from '@promotionAg/usePromotionAgTrips' */
      // input Flight Trips
      onOpenModalInputFlightTrips,
      addTrip,
      onInputFlightTrip,
      onEnterFlightTrip,
      /* !imported from '@promotionAg/usePromotionAgTrips' */
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.v-select-wrap {
  .vs__selected-options {
    flex-wrap: wrap;
  }
}
</style>
